import * as React from 'react';

// ⚠️ This page should be removed.
// If we remove it now, the /brand-system/src/sass/styles.scss
// is not loaded and css is missing on all pages.
// This is a temporary fix until we can find a better solution.

/*
⚠️ You can use this page for force tailwind to keep some CSS class
after the purge.
For exemple, you use a css class in datoCMS.
The purge can't see this and keep this one.
If you put here, the purge can keep it.
*/
export const Head = ({ data, pageContext, location }: any) => (
  <meta name="robots" content="noindex" />
);
const ServicePage = () => {
  return <div className="text-pink-71">Page de Test</div>;
};

export default ServicePage;
